<template>
  <b-row class="match-height">
    <b-col
      xl="4"
      md="6"
    >
      <ecommerce-medal />
    </b-col>
    <b-col
        xl="4"
        md="6"
    >
      <ecommerce-medal />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import EcommerceMedal from './EcommerceMedal.vue'

export default {
  components: {
    BRow,
    BCol,
    EcommerceMedal,
  },
}
</script>

<style scoped>

</style>
